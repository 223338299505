<template>
  <v-card elevation="1">
    <v-toolbar height="45" flat color="accent" light>
      <v-toolbar-title class="white--text">{{ $route.meta.title }}</v-toolbar-title>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-tabs color="accent">
      <v-tab>
        <v-icon left>{{ icons.mdiPlus }}</v-icon>
        Create Appointment
      </v-tab>
      <v-tab>
        <v-icon left>{{ icons.mdiBell }}</v-icon>
        Reminders & Notifications
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col cols="12" md="8" sm="8">
                  <v-text-field
                    v-model="appointment.event_name"
                    color="secondary"
                    label="Title"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="appointment.background_color"
                    hide-details
                    color="secondary"
                    dense
                    label="Color (Optional)"
                    outlined
                  >
                    <template v-slot:append>
                      <v-menu
                        v-model="color_menu"
                        top
                        nudge-bottom="105"
                        nudge-left="16"
                        :close-on-content-click="false"
                        solo
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle" class="mt-n1" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="appointment.background_color" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-dialog
                    ref="startdate_menu"
                    v-model="startdate_menu"
                    :return-value.sync="appointment.start_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="appointment.start_date"
                        label="From Date"
                        color="secondary"
                        :prepend-inner-icon="icons.mdiCalendarAccount"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="appointment.start_date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="startdate_menu = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.startdate_menu.save(appointment.start_date)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-dialog
                    ref="starttime_menu"
                    v-model="starttime_menu"
                    :return-value.sync="appointment.start_time"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="appointment.start_time"
                        label="Start time"
                        :prepend-inner-icon="icons.mdiClockTimeFourOutline"
                        readonly
                        outlined
                        dense
                        color="secondary"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker v-if="starttime_menu" v-model="appointment.start_time" full-width>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="starttime_menu = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.starttime_menu.save(appointment.start_time)">
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-dialog
                    ref="enddate_menu"
                    v-model="enddate_menu"
                    :return-value.sync="appointment.end_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="appointment.end_date"
                        label="To Date"
                        color="secondary"
                        :prepend-inner-icon="icons.mdiCalendarAccount"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="appointment.end_date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="enddate_menu = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.enddate_menu.save(appointment.end_date)"> OK </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-dialog
                    ref="endtime_menu"
                    v-model="endtime_menu"
                    :return-value.sync="appointment.end_time"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="appointment.end_time"
                        label="End time"
                        :prepend-inner-icon="icons.mdiClockTimeFourOutline"
                        readonly
                        outlined
                        dense
                        color="secondary"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker v-if="endtime_menu" v-model="appointment.end_time" full-width>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="endtime_menu = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.endtime_menu.save(appointment.end_time)"> OK </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    background-color="transparent"
                    v-model="appointment.notes"
                    color="secondary"
                    dense
                    label="Notes (Optional)"
                    outlined
                  ></v-textarea>
                  <v-checkbox
                    v-model="appointment.all_day"
                    label="All Day"
                    color="primary"
                    :value="appointment.all_day"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-autocomplete
                    v-model="appointment.estimate_id"
                    :items="estimates"
                    item-value="estimate_id"
                    item-text="estimate_number"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Estimate Number"
                    :prepend-inner-icon="icons.mdiCardAccountDetails"
                    single-line
                  ></v-autocomplete>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-autocomplete
                    v-model="appointment.user_id"
                    :items="users"
                    item-value="user_id"
                    item-text="username"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Username"
                    :prepend-inner-icon="icons.mdiAccountSupervisor"
                    single-line
                  ></v-autocomplete>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-autocomplete
                    v-model="appointment.customer_id"
                    :items="customers"
                    item-value="customer_id"
                    item-text="customer_name"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Customer Name"
                    :prepend-inner-icon="icons.mdiAccountTie"
                    single-line
                  ></v-autocomplete>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-autocomplete
                    v-model="appointment.vehicle_id"
                    :items="vehicles"
                    item-value="vehicle_id"
                    item-text="vehicle_name"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Vehicle Name"
                    :prepend-inner-icon="icons.mdiCar"
                    single-line
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col md="12" sm="12" cols="12">
                  <v-layout>
                    <h3>Confirmation & Reminders</h3>
                    <v-spacer></v-spacer>
                    <v-switch
                      v-model="appointment.send_confirmation"
                      label="Send Confirmation"
                      class="mt-0"
                      color="primary"
                      :value="appointment.send_confirmation"
                      hide-details
                    ></v-switch>
                  </v-layout>
                </v-col>
                <v-col md="12" sm="12" cols="12">
                  <v-text-field
                    v-model="appointment.email_to_confirm"
                    color="secondary"
                    label="Email"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="12" sm="12" cols="12">
                  <v-text-field
                    v-model="appointment.subject_to_confirm"
                    color="secondary"
                    label="Subject"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    background-color="transparent"
                    v-model="appointment.message_to_confirm"
                    color="secondary"
                    dense
                    label="Message"
                    outlined
                  ></v-textarea>
                </v-col>
                <v-col md="12" sm="12" cols="12">
                  <v-layout>
                    <h3>Send Reminders</h3>
                    <v-spacer></v-spacer>
                    <v-switch
                      v-model="appointment.send_reminder"
                      label="Send Reminders"
                      class="mt-0"
                      color="primary"
                      :value="appointment.send_reminder"
                      hide-details
                    ></v-switch>
                  </v-layout>
                </v-col>
                <v-col md="12" sm="12" cols="12">
                  <v-text-field
                    v-model="appointment.email_to_send"
                    color="secondary"
                    label="Email"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="12" sm="12" cols="12">
                  <v-text-field
                    v-model="appointment.subject_to_send"
                    color="secondary"
                    label="Subject"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    background-color="transparent"
                    v-model="appointment.message_to_send"
                    color="secondary"
                    dense
                    label="Message"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" rounded @click.prevent="addNewAppointment">
              <v-icon right light>{{ icons.mdiContentSave }}</v-icon>
              <span>Save</span>
            </v-btn>
            <v-btn color="secondary" rounded @click.prevent="$router.push({ name: '' })">
              <v-icon right light>{{ icons.mdiClose }}</v-icon>
              <span>Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
import moment from 'moment'

import {
  mdiAccountTie,
  mdiCardAccountDetails,
  mdiAccountSupervisor,
  mdiCar,
  mdiPlus,
  mdiInformation,
  mdiContentSave,
  mdiBell,
  mdiCalendarAccount,
  mdiClose,
  mdiClockTimeFourOutline,
} from '@mdi/js'
export default {
  name: 'AddNewAppointment',
  components: {},

  created: function () {
    this.selectEstimates()
    this.selectCustomers()
    this.selectVehicles()
    this.selectUsers()
  },
  data() {
    return {
      appointment: {
        customer_id: '',
        vehicle_id: '',
        estimate_id: '',
        background_color: '#2596be',
        user_id: '',
        event_name: '',
        start_date: '',
        start_time: null,
        end_date: '',
        end_time: null,
        all_day: '',
        send_confirmation: '',
        email_to_confirm: '',
        send_reminder: '',
        event_id: '',
        email_to_send: '',
        subject_to_confirm: '',
        message_to_confirm: '',
        subject_to_send: '',
        message_to_send: '',
      },
      color_menu: false,
      startdate_menu: false,
      starttime_menu: false,
      enddate_menu: false,
      endtime_menu: false,
      users: [],
      estimates: [],
      customers: [],
      vehicles: [],
      filterCustomer: [],
      filterEstimate: [],
      filterUser: [],
      filterVehicle: [],

      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiBell,
        mdiCalendarAccount,
        mdiCar,
        mdiCardAccountDetails,
        mdiAccountSupervisor,
        mdiClockTimeFourOutline,
      },
    }
  },
  methods: {
    addNewAppointment: function () {
      this.$store
        .dispatch(`appointment/storeNewAppointment`, this.appointment)
        .then(response => {
          this.$router.push({ name: 'calendar' })
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record added successfully',
          })
          //this.emitter.emit('loadAppointments')
        })
        .catch(error => {
          console.log(error)
        })
    },
    selectUsers: function () {
      this.$store
        .dispatch('appointment/selectUsername')
        .then(response => {
          this.users = this.selectUser
        })
        .catch(err => {
          console.log(err)
        })
    },
    selectEstimates: function () {
      this.$store
        .dispatch('estimate/selectEstimate')
        .then(response => {
          this.estimates = this.selectEstimate
        })
        .catch(err => {
          console.log(err)
        })
    },

    selectUsers: function () {
      this.$store
        .dispatch('appointment/selectUsername')
        .then(response => {
          this.users = this.selectUser
        })
        .catch(err => {
          console.log(err)
        })
    },

    selectCustomers: debounce(function () {
      this.$store
        .dispatch('estimate/selectCustomer')
        .then(response => {
          ;(this.customers = this.selectCustomer), response
        })
        .catch(err => {
          console.log(err)
        })
    }, 800),

    selectVehicles: debounce(function () {
      this.$store
        .dispatch('estimate/selectVehicle')
        .then(response => {
          ;(this.vehicles = this.selectVehicle), response
        })
        .catch(err => {
          console.log(err)
        })
    }, 800),
  },

  computed: {
    ...mapGetters({
      fetchAppointments: 'appointment/fetchAppointments',
      selectCustomer: 'estimate/selectCustomer',
      selectVehicle: 'estimate/selectVehicle',
      selectUser: 'appointment/fetchUsers',
      selectEstimate: 'estimate/fetchEstimates',
    }),

    swatchStyle: function () {
      const { color, menu } = this
      return {
        backgroundColor: this.appointment.background_color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>